// 根据不同环境设置
// 接口地址公共前缀
var axiosBaseURL = '/';
var websocketBaseURL = '';
// development test // uat //prod
if (APP_ENV == 'development' || APP_ENV == 'local') {
  // axiosBaseURL = 'http://121.40.204.59:8443/' //开发
  // axiosBaseURL = 'http://218.245.102.94:8443/' //测试
  axiosBaseURL = 'https://back.yuexinguoji.com/'; //正试
  // axiosBaseURL = 'http://8.134.13.164:9527/' //uat
  // axiosBaseURL="https://sysadmin.yuexinguoji.com/"//生产
  // axiosBaseURL = 'http://192.168.1.151:8010/' //开发人员本地
  websocketBaseURL = 'ws://218.245.102.94:8443/';
  // websocketBaseURL = 'ws://218.245.102.94:8443/'  //测试 
} else if (APP_ENV == 'test') {
  axiosBaseURL = '/';
  websocketBaseURL = 'ws://218.245.102.94:8443/';
} else if (APP_ENV == 'uat') {
  axiosBaseURL = '/';
  websocketBaseURL = 'ws://8.134.13.164:8443/';
} else if (APP_ENV == 'prod') {
  axiosBaseURL = '/';
  // websocketBaseURL = 'ws://8.134.61.76:8443/' 
  websocketBaseURL = 'wss://sysadmin.yuexinguoji.com/';
} else {
  axiosBaseURL = '/';
}

// 根据不同环境设置
// 融云appkey
var ServiceChatAppKey = '';
if (APP_ENV == 'prod') {
  axiosBaseURL = '/';
  ServiceChatAppKey = {
    appkey: 'e0x9wycfephyq'
  }; //生产key
} else {
  ServiceChatAppKey = {
    appkey: '82hegw5u8v3wx'
  }; //测试key
}
module.exports = {
  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: true,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,
  /**
   * @type {boolean} true | false 
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * @type {string | array} 'production' | ['production', 'development'] 
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
  env: 'development',
  ServiceChatAppKey: ServiceChatAppKey,
  axiosBaseURL: axiosBaseURL,
  websocketBaseURL: websocketBaseURL
};